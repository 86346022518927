exports.onInitialClientRender = () => {
  setTimeout(function() {
    document.getElementById("___loader").style.display = "none"
    document.body.style.overflowY = "visible"
  }, 1000)
  document.getElementById("___gatsby").style.display = "block"
  document.body.style.overflowY = "hidden"
}

const React = require("react")
const CookieBot = require("react-cookiebot/lib/CookieBot")
const domainGroupId = "44ffbbb2-6108-4e17-99cd-50e92c3e9acd"

exports.wrapPageElement = ({ element }) => {
  return (
    <div>
      <CookieBot domainGroupId={domainGroupId} />
      {element}
    </div>
  )
}
